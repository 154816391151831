import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import CreateTimeTableRow from '../../Components/PreExamination/CreateTimeTableRow';
import { sessionOpt } from '../../Data/jsonData/Academics/Academics';
import { getFileUrl } from '../../Helpers/Helpers';
import { ACADEMICS_ADD_SUBJECT } from '../../utils/Academics.apiConst';
import { ASSET_EMPLOYEE_IMAGE } from '../../utils/AssetsReferenceTypes';
import { PRE_EXAM_TIMETABLE } from '../../utils/Examination.apiConst';

function ModalCreateTimetable(props) {

  //Destructuring props
  const {
    addData,
    data,
    programOpt,
    collegeOpt,
    classOpt,
    semesterOpt,
    showCollege,
    showProgram,
    showMonth,
    showYear,
    setLoading,
    showDepartment
  } = props;

  //Main obj
  const [user, setUser] = useState({
    class_examination_id: addData?.id,
    session_id: '',
    course_id: '',
    date: '',
    time_from: '',
    time_to: "",
    duration: "",
    max_marks: '',
    min_marks: '',
    attachment:'',
    attachment1:'',
  })


  //Function upload attachment to the s3
  const addAttachment = async (e, str,value) => {
    try {
        const d = await getFileUrl(ASSET_EMPLOYEE_IMAGE, `Examination_${str}`, e.target.value.split(".")[1], setLoading, e.target.files[0]);
        setUser(prev=>({
          ...prev,
          [value]:d?d:''
        }))
    } catch (error) {
        console.log(error);
    }
}


  //Other States for data management
  const [subOpt, setSubOpt] = useState([])

  //states holding created time table data
  const [timeTableData, setTimeTableData] = useState([])



  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prev => ({
      ...prev,
      [name]: value
    }));
  }


  //////get Subject Data
  const getbasicData = async () => {
    setLoading(1)
    const config = {
      method: 'get',
      url: `${ACADEMICS_ADD_SUBJECT}?college_id=${showCollege}&semester_id=${addData?.semester_id}&department_id=${showDepartment}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      }
    }

    await axios(config)
      .then(res => {
        setSubOpt(res.data.data)
      })
      .catch(err => {
        console.log(err);
      })

    setLoading(0)
  }

  //get time table data
  const getData = async () => {
    setLoading(1)
    const config = {
      method: 'get',
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${addData?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      }
    }

    await axios(config)
      .then(res => {
        console.log(res);
        setTimeTableData(res.data.data)
      })
      .catch(err => {
        console.log(err);
      })
    setLoading(0)
  }

  //submit form
  const handleSubmit = async () => {
    if(!user?.session_id) return toast.error("Please select the academic year")
    setLoading(1)
    const config = {
      method: 'post',
      url: PRE_EXAM_TIMETABLE,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: user
    }

    await axios(config)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err);
      })

    setLoading(0)
    getData();

  }

  const handleDeleteExam = async (i) => {
    const config = {
      method: 'put',
      url: PRE_EXAM_TIMETABLE + '/' + i,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      }
    }

    await axios(config)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err);
      })

    setLoading(0)
    getData();
  }


  ////useEffects
  useEffect(() => {
    if (addData) {
      setUser({
        class_examination_id: addData?.id,
        session_id: '',
        course_id: '',
        date: '',
        time_from: '',
        time_to: "",
        duration: "",
        max_marks: '',
        min_marks: ''
      })
      getbasicData();
      getData();
    }
  }, [addData])


  return (
    <div
      className="modal fade"
      id="create"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-100 w-100 ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Create Time Table
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Program<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                   readOnly
                    
                    value={showProgram}
                  >
                    <option value="">Select Program</option>
                    {
                      programOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Faculty<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                    readOnly
                    value={showCollege}
                  >
                    <option value="">Select Faculty</option>
                    {
                      collegeOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Class<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                    readOnly
                    value={addData?.class_id}
                  >
                    <option value="">Select Class</option>
                    {
                      classOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Semester<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                    readOnly
                    value={addData?.semester_id}
                  >
                    <option value="">Select Semester</option>
                    {
                      semesterOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <label htmlFor="">Date</label>

                <input
                  type="text"
                  name="date"
                  id="date"
                  readOnly
                  value={showMonth + '.' + showYear}
                  className="form-control"
                />
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="validationCustom02">
                    Academic Year<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="session_id"
                    className="form-control"
                    value={user?.session_id}
                    onChange={handleChange}
                  >
                    <option value=""> Select Academic Year </option>
                    {
                      sessionOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>



            <div className="row mt-4">
              <div className="col-lg-12  table-responsive ">
                <table className="table table-bordered nowrap table-hover " id="tab_logic">
                  <thead>
                    <tr>
                      <th>Subjects</th>
                      <th>Date</th>
                      <th>Time From</th>
                      <th>Time To</th>
                      <th>
                        Duration <br /> (in min)
                      </th>

                      <th> Marks (Max..)</th>
                      <th>Marks (Min..)</th>
                      <th>Instructions</th>
                      <th>Remuneration</th>

                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody id="examsub">
                    {/* <div></div> */}
                    {
                      timeTableData?.map((i, key) => (
                        <CreateTimeTableRow data={i} key={key} setLoading={setLoading} handleDeleteExam={handleDeleteExam} subOpt={subOpt} getData={getData}/>
                        // <tr key={key}>
                        //   <td>{subOpt?.find(s => s.id == i?.course_id)?.name}</td>
                        //   <td>{i?.date?.split("T")[0]}</td>
                        //   <td>{i?.time_from}</td>
                        //   <td>{i?.time_to}</td>
                        //   <td>{i?.duration}</td>
                        //   <td>{i?.max_marks}</td>
                        //   <td>{i?.min_marks}</td>
                        //   <td>{i?.attachment ? <a href={i?.attachment} target="_blank">Click Here</a> :'No Attachments added'}  </td>
                        //   <td>
                        //     <button
                        //       id="delete_row"
                        //       className="pull-right btn btn-danger text-white btn-sm"
                        //       onClick={() => { handleDeleteExam(i?.id) }}
                        //     >
                        //       Delete
                        //     </button>
                        //   </td>
                        // </tr>
                      ))
                    }
                    <tr id="addr0">
                      <td>
                        <select
                          name="course_id"
                          className="form-control"
                          value={user?.course_id}
                          onChange={handleChange}
                        >
                          <option value="">Select Subject</option>
                          {
                            subOpt?.map((i, key) => (
                              <option key={key} value={i?.id}>{i?.name}</option>
                            ))
                          }
                        </select>
                      </td>
                      <td>
                        <input
                          type="date"
                          name="date"
                          className="form-control"
                          value={user?.date}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="time_from"
                          className="form-control"
                          value={user?.time_from}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="time_to"
                          className="form-control"
                          value={user?.time_to}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          name="duration"
                          className="form-control"
                          value={user?.duration}
                          onChange={handleChange}
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          name="max_marks"
                          className="form-control"
                          value={user?.max_marks}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          name="min_marks"
                          className="form-control"
                          value={user?.min_marks}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="file"
                          name="attachment"
                          className="form-control"
                          // value={user?.attachment}
                          onChange={(e)=>{addAttachment(e,'timeTable_instructions','attachment')}}
                        />
                      </td>
                      <td>
                        <input
                          type="file"
                          name="attachment"
                          className="form-control"
                          // value={user?.attachment}
                          onChange={(e)=>{addAttachment(e,'timeTable_renumeration','attachment1')}}
                        />
                      </td>

                      <td>
                        <a
                          id="delete_row"
                          className="pull-right btn btn-success text-white btn-sm"
                          onClick={handleSubmit}
                        >
                          Add
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              aria-label="Close"
            >Save changes</button>
          </div>
        </div>

        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  )
}

export default ModalCreateTimetable