import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { QPDS_VERIFY_SETTER } from '../../../utils/Examination.apiConst'

const ExamSetter = () => {

    const [loading, setLoading] = useState(1)

    const {id} = useParams()

    const [isSuccess, setIsSuccess] = useState(0)

    const verifyToken = async() => {
        const config = {
            method:'post',
            url:QPDS_VERIFY_SETTER,
            headers: {
                Authorization: `Bearer ${id}`,
                "Content-Type": "application/json",
              },
        }
        
        await axios(config)
        .then(res=>{
            console.log(res);
            if(res?.status==403){
                toast.warning('Already Approved')
            }
            setIsSuccess(1)
        })
        .catch(err=>{
            console.log(err);
            if(err?.response?.status==403){
                toast.warning('Already Approved')
            }
            setIsSuccess(0)
        })

        setLoading(0)
    }

    useEffect(()=>{
        if(id) verifyToken()
    },[id])

    if (loading) {
        return (
            <div className='p-5'>Loading ....</div>
        )
    }
    else {
        return (
            <div className='p-5'>{
                isSuccess
                ?
                <div >
                    <div className='text-success'>
                        Verification Success  
                    </div>
                    <div>Credentials has been sent to your Email</div>
                    <div className='mt-3'>
                        <a href="https://nexcorpexams.nexenstial.org/login/setter">Click here to login</a>
                    </div>
                </div>
                :
                <div className='text-danger'>
                    Verification Failed
                </div>
            }</div>
        )
    }


}

export default ExamSetter