import React, { useEffect } from "react";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { TimePicker } from "antd";
import { Http } from "../../../../Services/Services";
import { RE_EXAM_TIMETABLE } from "../../../../utils/Examination.apiConst";
import { ACADEMICS_ADD_SUBJECT } from "../../../../utils/Academics.apiConst";
import { toast } from "react-toastify";

const TimeTable = () => {
  const { examId } = useParams();

  const { examData } = useOutletContext();

  console.log(examData);

  const [data, setData] = useState([]);
  const [subjectOpt, setSubjectOpt] = useState([]);

  const [user, setUser] = useState({
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
    instructions: "",
    remuneration: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = () => {
    Http.get(`${RE_EXAM_TIMETABLE}?re_examination_id=${examId}`)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubjectData = () => {
    Http.get(`${ACADEMICS_ADD_SUBJECT}?semester_id=${examData?.semester_id}`)
      .then((res) => {
        setSubjectOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    console.log(user);
    Http.post(RE_EXAM_TIMETABLE, { ...user, re_examination_id: examId })
      .then((res) => {
        toast.success("Time Table Added Successfully");
        getData();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (examId) getData();
  }, [examId]);

  useEffect(() => {
    if (examData?.semester_id) getSubjectData();
  }, [examData?.semester_id]);
  return (
    <div className="card" style={{ maxWidth: "100%", overflow: "scroll" }}>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <h6 className="card-header">Time Table</h6>
          </div>
        </div>

        <div className="row mt-2">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tr>
                <th>Sl. No</th>
                <th style={{ minWidth: "150px" }}>Subjects</th>
                <th>Date</th>
                <th style={{ minWidth: "150px" }}>Time From</th>
                <th style={{ minWidth: "150px" }}>Time To</th>
                <th>
                  Duration <br /> (in min)
                </th>

                <th style={{ minWidth: "100px" }}> Marks (Max..)</th>
                <th style={{ minWidth: "100px" }}>Marks (Min..)</th>
                <th>Instructions</th>
                <th>Remuneration</th>

                <th>Action</th>
              </tr>
              {data &&
                data.map((i, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      {subjectOpt?.find((s) => s.id == i?.course_id)?.name}
                    </td>
                    <td>{i?.date?.split("T")[0]}</td>
                    <td>{i?.time_from}</td>
                    <td>{i?.time_to}</td>
                    <td>{i?.duration}</td>
                    <td>{i?.max_marks}</td>
                    <td>{i?.min_marks}</td>
                    <th>Instructions</th>
                    <th>Remuneration</th>

                    <th>Action</th>
                  </tr>
                ))}
              <tr>
                <td>{data.length + 1}</td>
                <td>
                  <select
                    name="course_id"
                    className="form-control"
                    value={user.course_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Subject</option>
                    {subjectOpt &&
                      subjectOpt.map((i, key) => (
                        <option key={key} value={i?.id}>
                          {i?.name}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    value={user.date}
                    onChange={handleChange}
                    name="date"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control"
                    id=""
                    value={user.time_from}
                    onChange={handleChange}
                    name="time_from"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control"
                    id=""
                    value={user.time_to}
                    onChange={handleChange}
                    name="time_to"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.duration}
                    onChange={handleChange}
                    name="duration"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.max_marks}
                    onChange={handleChange}
                    placeholder="Enter Max marks"
                    name="max_marks"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.min_marks}
                    onChange={handleChange}
                    placeholder="Enter Min marks"
                    name="min_marks"
                  />
                </td>
                <td>
                  <input type="file" className="form-control" />
                </td>
                <td>
                  <input type="file" className="form-control" />
                </td>
                <td>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary btn-sm"
                  >
                    add
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;
