import { BASE_URL } from "./apiConstants";

//pre examination
export const PRE_EXAM_COMMITTEE = `${BASE_URL}/api/exam/pre/exam-committee`; //CRUD

export const PRE_CLASS_EXAM = `${BASE_URL}/api/exam/pre/class-exam`; //CRUD

export const PRE_LOGIN_STAFF = `${BASE_URL}/api/exam/pre/class-exam/staff/login`; //CRUD

export const PRE_EXAM_TIMETABLE = `${BASE_URL}/api/exam/pre/timetable`; //CRUD

export const PRE_EXAM_INVALIGATOR = `${BASE_URL}/api/exam/pre/invigilator`; //CRUD

export const PRE_EXAM_EVALUATOR = `${BASE_URL}/api/exam/pre/evaluator`; //CRUD

export const PRE_EXAM_ENROLL_STUDENT = `${BASE_URL}/api/exam/pre/student`; //CRUD

export const PRE_EXAM_ENROLL_STUDENT1 = `${BASE_URL}/api/exam/pre/student1`; //CRUD

export const QPDS_QP_PATTERN = `${BASE_URL}/api/exam/qpds/pattern`; //CRUD

export const QPDS_QP_SETTER = `${BASE_URL}/api/exam/qpds/setter`; //CRUD

export const QPDS_VERIFY_SETTER = `${BASE_URL}/api/exam/qpds/setter/verify`; //CRUD

export const QPDS_LOGIN_SETTER = `${BASE_URL}/api/exam/qpds/setter/login`; //CRUD

export const QPDS_SETTER_UPLOAD_QP = `${BASE_URL}/api/exam/qpds/paper`; //CRUD

// post examination

// Add marks
export const POST_EXAM_ADD_MARKS = `${BASE_URL}/api/exam/post/marks`;

// Add marks bulk
export const POST_EXAM_ADD_MARKS_BULK = `${BASE_URL}/api/exam/post/marks/bulk`;

//Fetch Data for rechecking
export const POST_EXAM_FETCH_DATA_FOR_RECHECKING = `${BASE_URL}/api/exam/post/rechecking/req-data`;

//Revaluation
export const POST_EXAM_REVALUATION = `${BASE_URL}/api/exam/post/rechecking`;

///////////////////////////////////////////////////////////////////
//////////////////////REPORTS//////////////////////////////////////
///////////////////////////////////////////////////////////////////

///////////Reports in numbers
export const REPORTS_BY_NUMBERS = `${BASE_URL}/api/exam/post/results/data`;
export const REPORTS_DETAILED = `${BASE_URL}/api/exam/post/results/by-sub-and-student`;
export const REPORTS_DETAILED_ENG = `${BASE_URL}/api/exam/post/results/by-sub-and-student-eng`;

///////////////////////////////////////////////////////////////////
//////////////////////   Re-Examination   /////////////////////////
///////////////////////////////////////////////////////////////////

export const RE_EXAM_EXAM = `${BASE_URL}/api/exam/re-exam/exam`; //CRUD

export const RE_EXAM_TIMETABLE = `${BASE_URL}/api/exam/re-exam/time-table`; //CRUD

export const RE_EXAM_ENROLLMENT = `${BASE_URL}/api/exam/re-exam/enrollment`; //CRUD

export const RE_EXAM_MARKS = `${BASE_URL}/api/exam/re-exam/marks`; //CRUD

export const RE_EXAM_REPORT_DETAILED = `${BASE_URL}/api/exam/re-exam/reports/detailed`; //CRUD

export const RE_EXAM_RECHECKING = `${BASE_URL}/api/exam/re-exam/rechecking`; //CRUD

export const RE_EXAM_QPDS_PATTERN = `${BASE_URL}/api/exam/re-exam/qpds/pattern`; //CRUD

export const RE_EXAM_QPDS_SETTER = `${BASE_URL}/api/exam/re-exam/qpds/setter`; //CRUD

export const RE_EXAM_QPDS_SETTER_CREATE = `${BASE_URL}/api/exam/re-exam/qpds/setter/create`; //CRUD

export const RE_EXAM_QPDS_LOGIN_SETTER = `${BASE_URL}/api/exam/re-exam/qpds/setter/login`; //CRUD

export const RE_EXAM_QPDS_SETTER_UPLOAD_QP = `${BASE_URL}/api/exam/re-exam/qpds/paper`; //CRUD
