import axios from 'axios'
import Multiselect from 'multiselect-react-dropdown'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import useEmployee from '../../Hooks/Employee/useEmployee'
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SEMESTER } from '../../utils/Academics.apiConst'
import { PRE_CLASS_EXAM, PRE_EXAM_COMMITTEE } from '../../utils/Examination.apiConst'
import { LOCAL_COLLEGE, LOCAL_PROGRAM } from '../../utils/LocalStorageConstants'




function Create_Exams({setLoading}) {

  ///Methods to get local data
  const getLocalPrograms = () => localStorage.getItem(LOCAL_PROGRAM)?JSON.parse(localStorage.getItem(LOCAL_PROGRAM)):null
  const getLocalColleges = () => localStorage.getItem(LOCAL_COLLEGE)?JSON.parse(localStorage.getItem(LOCAL_COLLEGE)):null


  //Main Object
  const [user, setUser] = useState(
    {
      program_id: '',
      college_id: '',
      month:'',
      year:'',
      exam_committee_id : '',
      class_id : '',
      semester_id : '',
    }
  )

  //States holds dropdown data
  const [ProgramOpt, setProgramOpt] = useState(getLocalPrograms())
  const [collegeOpt, setCollegeOpt] = useState(getLocalColleges())
  const [employeeOpt, setEmployeeOpt] = useState([])
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id)
  const [selectedEmployeeArray,setSelectedEmployeeArray] = useState([])
  const [classOpt, setClassOpt] = useState([])
  const [semOpt, setSemOpt] = useState([])


  //main Data
  const [data,setData] = useState([])

  //committee data
  const [committee, setCommittee] = useState()

  //Other States
  const [newValue, setNewValue] = useState(0)
  const [edit, setEdit] = useState(false)

  const [showProgram, setShowProgram] = useState()
  const [showCollege, setShowCollege] = useState()
  const [showMonth, setShowMonth] = useState()
  const [showYear, setShowYear] = useState()


   //handle Change function for every change in value
   const handleChange = (e) =>{
    const {name,value} = e.target;
    setUser(prev => ({
      ...prev,
      [name]:value
    }))
  }


  //Get Dropdown Data
  const getBasicData = async() =>{
    setLoading(1)
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
        method:'get'
      },
    }
    const [data1, data2] = await Promise.all([
      axios({...config,url:`${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`})
      .then(res=>{
        setClassOpt(res.data.data)
      })
      .catch(err=>{
        console.log(err);
        toast.error("Something Went wrong")
      })
      ,
      axios({...config,url:`${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`})
      .then(res=>{
        setLoading(0)
        setSemOpt(res.data.data)
      })
      .catch(err=>{
        console.log(err);
        setLoading(0)
        toast.error("Something Went wrong")
      })
    ])
  }

  //Get Exam Details
  const getData = async(exam_id) => {
    setLoading(1)
    const config = {
      method:'get',
      url:`${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      }
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      setData(res.data.data)
    })
    .catch(err=>console.log(err))


    await setShowCollege(user?.college_id)
    await setShowProgram(user?.program_id)
    await setShowMonth(user?.month)
    await setShowYear(user?.year)
    

    setLoading(0)
  }


  //Get Committee Data
  const getCommiittee = async() =>{
    if(!user?.program_id || !user.college_id || !user?.month) return toast.error('Mandatory fileds are required');
    setLoading(1)
    const config = {
      method:'get',
      url:`${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }
    await axios(config)
    .then(async res=>{
      if(res.data.data.length!=0)
      {
        setCommittee(res.data.data[0])
        const m = []
        empOpt?.map(s=>{
          if(res.data.data[0]?.employee?.find(k=>k==s?.id)) m.push(s)
      })
      setNewValue(true)
        setEmployeeOpt(m)
        await setUser(prev=>({
          ...prev,
          exam_committee_id:res.data.data[0].id
        }))
      getData(res.data.data[0].id);
      }
      else{
        toast.warning('Create a committee of this filter')
      }
    })
    .catch(err=>{
      toast.error('Something Went Wrong')
    })
    
    setLoading(0)
    getBasicData();
  }
  
  
  //Submit Data
  const handleSubmit = async() =>{
    if(selectedEmployeeArray.length==0) return toast.error('atleast one employee required')
    setLoading(1)
    
    const config = {
      method:'post',
      url:`${PRE_CLASS_EXAM}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data:{
        exam_committee_id:user?.exam_committee_id,
        class_id : user?.class_id,
        semester_id : user?.semester_id,
        employee : selectedEmployeeArray?.map(item => item.id),
      }
    }

    await axios(config)
    .then(res=>{
      setLoading(0)
      console.log(res);
      getData(user?.exam_committee_id)
    })
    .catch(err=>{
      setLoading(0)
      toast.error('Something went wrong')
    })

  }
  

  


  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0">Assign Staff</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="javascript: void(0);">Examination</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {" "}
                    Assign Staff
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Select Criteria</h2>
                <br />

                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="income source">
                      Program<span style={{ color: "red" }}>*</span>
                      </label>
                      <select 
                      class="form-control" 
                      name="program_id" 
                      id="course"
                      value={user.program_id}
                      onChange={handleChange}
                      >
                        <option value="">Select Program</option>
                        {
                          ProgramOpt?.map((i,key)=>(
                            <option value={i.id} key={key}>{i.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="income source">
                      Faculty<span style={{ color: "red" }}>*</span>
                      </label>
                      <select 
                      class="form-control" 
                      name="college_id" 
                      id="speci"
                      value={user?.college_id}
                      onChange={handleChange}
                      >
                        <option value="">Select Faculty</option>
                        {
                          collegeOpt?.map((i,key)=>(
                            <option value={i?.id} key={key}>{i?.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Year</label>
                      <input 
                      type="month" 
                      className='form-control' 
                      name='year' 
                      id="year"
                      value={user?.year + '-' + user?.month}
                      onChange={e=>{
                        setUser(prev=>({
                          ...prev,
                          month:e.target.value?.split("-")[1],
                          year:e.target.value?.split("-")[0],
                        }))
                      }}
                      />
                    </div>
                  </div>
                  {
                    newValue
                    ?
                    <>
                    <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Class</label>
                      <select 
                      name="class_id" 
                      className="form-control" 
                      id="sem"
                      value={user.class_id}
                      onChange={handleChange}
                      >
                        <option value="">Select Class</option>
                        {
                          classOpt?.map((i,key)=>(
                            <option value={i.id} key={key}>{i.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select 
                      name="semester_id" 
                      className="form-control" 
                      id="sem"
                      value={user.semester_id}
                      onChange={handleChange}
                      >
                        <option value="">Select </option>
                        {
                          semOpt?.filter(s=>s.class_id==user?.class_id)?.map((i,key)=>(
                            <option value={i.id} key={key}>{i.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="income source">
                      Staff<span style={{ color: "red" }}>*</span>
                      </label>
                      <Multiselect
                              options={employeeOpt}
                              selectedValues={selectedEmployeeArray}
                              onSelect={(p) => { setSelectedEmployeeArray(p) }}
                              onRemove={(p) => { setSelectedEmployeeArray(p) }}
                              displayValue={"first_name"}   
                            />
                    </div>
                  </div>
                    </>
                  :
                  null
                  }
                  
                 
                </div>
                {
                      newValue
                      ?
                      <div className="row ">
                        {
                          edit
                          ?
                          <div className="col-md-12 ">
                          <button
                            className="btn btn-nex btn-rounded float-right  "
                            type="submit"
                            name="submit"
                            // onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Edit
                          </button>
                        </div>:
                        <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Save
                        </button>
                      </div>
                        }
                      </div>
                      :
                      <div className="row ">
                        <div className="col-md-12 ">
                          <button
                            className="btn btn-nex btn-rounded float-right  "
                            type="submit"
                            name="submit"
                            onClick={getCommiittee}
                          >
                            <i className="fa fa-search" aria-hidden="true" /> Search
                          </button>
                        </div>
                      </div>

                    }
              </div>
            </div>
            {/* end card */}
          </div>
        </div>


        <div className="row">

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className='card-title text-uppercase '>Exams List</h4>
                <hr />

                <div className="table-responsive">

                  <table
                    id=""
                    className="display table table-bordered  nowrap table-hover "
                    style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th> Sl. No.</th>
                        <th>Course</th>
                        <th>Specialization</th>
                        <th>Date</th>

                        <th>Faculties</th>

                        <th>Course Year</th>

                        <th>Semester</th>



                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>



                      {
                        data && data?.map((i, key) => {
                          return <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{ProgramOpt?.find(s=>s.id==showProgram)?.name}</td>
                            <td> {collegeOpt?.find(s=>s.id==showCollege)?.name}</td>
                            <td > {showMonth + ',' + showYear}</td>
                            <td> {i?.employee?.map((j,key2)=>(
                                  <div>{key2+1}. {employeeOpt?.find(s => s.id == j)?.first_name}</div>
                                ))}</td>
                            <td> {classOpt?.find(s=>s.id==i.class_id)?.name}</td>
                            <td> {semOpt?.find(s=>s.id==i.semester_id)?.name}</td>                           


                            <td><span className='badge badge-light text-dark mr-3' data-toggle="tooltip" title="Edit"> <i class="fa fa-edit " aria-hidden="true"></i></span>
                              <span className='badge badge-light text-danger mr-3' data-toggle="tooltip" title="Delete"> <i class="fa fa-trash " aria-hidden="true"></i></span>
                            </td>
                          </tr>
                          
                        })


                      }
                    </tbody>
                  </table>

               

                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
  )
}

export default Create_Exams