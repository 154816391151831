import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useEmployee from "../../Hooks/Employee/useEmployee";
import { PRE_EXAM_COMMITTEE, PRE_EXAM_EVALUATOR, PRE_EXAM_INVALIGATOR } from "../../utils/Examination.apiConst";
import { LOCAL_COLLEGE, LOCAL_PROGRAM } from "../../utils/LocalStorageConstants";

function AddEvaluators({ setLoading }) {
  ///Methods to get local data
  const getLocalPrograms = () => localStorage.getItem(LOCAL_PROGRAM) ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM)) : null
  const getLocalColleges = () => localStorage.getItem(LOCAL_COLLEGE) ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE)) : null


  //Main Object
  const [user, setUser] = useState(
    {
      exam_committee_id: '',
      is_employee: 'true',
      employee_id: '',
      name: "",
      email: "",
      program_id: '',
      college_id: '',
      month: '',
      year: '',
    }
  )

  //States holds dropdown data
  const [ProgramOpt, setProgramOpt] = useState(getLocalPrograms())
  const [collegeOpt, setCollegeOpt] = useState(getLocalColleges())
  const [employeeOpt, setEmployeeOpt] = useState([])
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id)

  //main Data
  const [data, setData] = useState([])

  //Other states
  const [newValue, setNewValue] = useState(false)


  //handle Change function for every change in value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prev => ({
      ...prev,
      [name]: value
    }))
  }


  //getInvaligator Data
  const getData = async(id) => {
    setLoading(1)
    const config = {
      method: 'get',
      url:`${PRE_EXAM_EVALUATOR}?exam_committee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      setData(res.data.data)
    })
    .catch(err=>{
      console.log(err)
    })

    setLoading(0)
  }

  //Get Committee Data
  const getCommiittee = async () => {
    if (!user?.program_id || !user.college_id || !user?.month) return toast.error('Mandatory fileds are required');
    setLoading(1)
    const config = {
      method: 'get',
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }
    await axios(config)
      .then(async res => {
        if (res.data.data.length != 0) {
          // setCommittee(res.data.data[0])
          const m = []
          empOpt?.map(s => {
            if (res.data.data[0]?.employee?.find(k => k == s?.id)) m.push(s)
          })
          setEmployeeOpt(m)
          setNewValue(true)
          await setUser(prev => ({
            ...prev,
            exam_committee_id: res.data.data[0].id
          }))
          getData(res.data.data[0].id)
        }
        else {
          toast.warning('Create a committee of this filter')
        }
      })
      .catch(err => {
        toast.error('Something Went Wrong')
      })

    setLoading(0)
  }

  const handleSubmit = async() => {
    setLoading(1)
    const config = {
      method: 'post',
      url:PRE_EXAM_EVALUATOR,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data:{
        "exam_committee_id": user?.exam_committee_id,
        "is_employee": user?.is_employee=='true'?true:false,
        "employee_id": user?.is_employee=='true'?user?.employee_id:'',
        "name": user?.is_employee=='true'?'':user?.name,
        "email": user?.is_employee=='true'?'':user?.email
      }
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      getData(user?.exam_committee_id)
    })
    .catch(err=>{
      console.log(err);
    })

    setLoading(0)
  }

  const [infoData, setInfoData] = useState([
    {
      course: "UG",
      Faculty: "Ayurveda",
      date: "08.2022",
      staffname: "Dr.Abhishek",
      college: "Nexenstial Ayurvedic College",

      email: "abhishek@nexenstial.com",
      status: <span className="badge badge-soft-success">Approved</span>,
    },
  ])






  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Add Evaluator</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Add Evaluator
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />




                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="income source">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            value={user.program_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Program</option>
                            {
                              ProgramOpt?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="income source">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="speci"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {
                              collegeOpt?.map((i, key) => (
                                <option value={i?.id} key={key}>{i?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className='form-control'
                            name='year'
                            id="year"
                            value={user?.year + '-' + user?.month}
                            onChange={e => {
                              setUser(prev => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }))
                            }}
                          />
                        </div>
                      </div>
                      {
                        newValue
                        ?
                        <>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="income source">
                                Is Employee<span style={{ color: "red" }}>*</span>
                              </label>
                              <select 
                                name="is_employee"
                                id=""
                                className="form-control"
                                value={user?.is_employee}
                                onChange={handleChange}
                               >
                                <option value={'true'}>true</option>
                                <option value={'false'}>false</option>
                               </select>
                            </div>
                          </div>
                          {
                            user?.is_employee=='true'
                            ?
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor=""></label>
                                <select 
                                name="employee_id" 
                                id="" 
                                value={user?.employee_id}
                                onChange={handleChange}
                                className="form-control"
                                >
                                  <option value="">Select Employee</option>
                                  {
                                    employeeOpt?.map((i,key)=>(
                                      <option value={i?.id} key={key}>{i?.first_name}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                            :
                            <>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="">Evaluator Name</label>
                                  <input 
                                  type="text"
                                  name="name"
                                  value={user?.name}
                                  onChange={handleChange}
                                  className='form-control'
                                  placeholder="Enter Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="">Evaluator Email</label>
                                  <input 
                                  type="email"
                                  name="email"
                                  value={user?.email}
                                  onChange={handleChange}
                                  className='form-control'
                                  placeholder="Enter Email"
                                  />
                                </div>
                              </div>
                            </>
                          }
                        </>
                        :
                        null
                      }
                    

                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        {
                          !newValue
                          ?
                          <button
                            className="btn btn-nex btn-rounded float-right  "
                            type="submit"
                            name="submit"
                            onClick={getCommiittee}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Check
                          </button>
                          :
                          <>
                            <button
                              className="btn btn-nex btn-rounded float-right  "
                              type="submit"
                              name="submit"
                              onClick={handleSubmit}
                            >
                              <i className="fa fa-save" aria-hidden="true" /> Save
                            </button>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>


            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className='card-title text-uppercase '>List</h4>
                    <hr />

                    <div className="table-responsive">

                      <table
                        id="table_id"
                        className="display table table-bordered  nowrap table-hover "
                        style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th> Sl. No.</th>

                            <th>Is Employee</th>
                            <th>Staff Name</th>
                            <th>Email-Id</th>


                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>



                          {
                            data && data?.map((i, key) => {
                              return <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{i.is_employee?'YES':'No'}</td>
                                <td > {i.is_employee?employeeOpt?.find(s=>s.id==i.employee_id)?.first_name:i?.name}</td>
                                <td > {i.is_employee?employeeOpt?.find(s=>s.id==i.employee_id)?.email:i?.email}</td>

                                <td>   <a data-toggle="modal"
                                  data-target="#create" className='badge badge-light text-dark mr-3' title="Create Timetable"> <i class="fa fa-edit " aria-hidden="true"></i></a>
                                </td>
                              </tr>

                            })


                          }
                        </tbody>
                      </table>



                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEvaluators;
