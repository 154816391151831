import React, { useEffect, useState } from "react";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../../utils/LocalStorageConstants";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import useEmployee from "../../../../Hooks/Employee/useEmployee";
import { Http } from "../../../../Services/Services";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../../utils/Academics.apiConst";
import { RE_EXAM_EXAM } from "../../../../utils/Examination.apiConst";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Router/routerConfig";

const Create = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    department_id: "",
    class_id: "",
    semester_id: "",
    session_id: "",
    month: "",
    year: "",
    employee_id: "",
  });

  const programOpt = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
  const collegeOpt = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const deptOpt = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [classOpt, setClassOpt] = useState([]);
  const [semesterOpt, setSemesterOpt] = useState([]);
  let [arr] = useEmployee();
  const arr2 = [];
  for (const i of arr) {
    arr2.push({ value: i.id, label: i.first_name + " " + i.last_name });
  }
  const [data, setData] = useState([]);

  const employeeOpt = arr2;

  console.log(employeeOpt);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getClassAndSemData = () => {
    Http.get(ACADEMICS_ADD_CLASS)
      .then((res) => {
        console.log(res);
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    Http.get(ACADEMICS_ADD_SEMESTER)
      .then((res) => {
        console.log(res);
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (
      !user.college_id ||
      !user.department_id ||
      !user.program_id ||
      !user.class_id ||
      !user.semester_id ||
      !user.session_id ||
      !user.month ||
      !user.year ||
      !user.employee_id
    )
      return toast.error("Please fill all the fields");
    const obj = {
      college_id: user.college_id,
      class_id: user.class_id,
      semester_id: user.semester_id,
      session_id: user.session_id,
      month: user.month,
      year: user.year,
      employee: user.employee_id,
    };
    Http.post(RE_EXAM_EXAM, obj)
      .then((res) => {
        console.log(res);
        toast.success("Successfully Created");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message || "Something went wrong");
      });
  };

  const getData = () => {
    Http.get(RE_EXAM_EXAM)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message || "Something went wrong");
      });
  };

  useEffect(() => {
    getClassAndSemData();
  }, [user.department_id, user.college_id]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h6>Re-Examination</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className=" card">
                  <div className="card-body">
                    <div className="card-header">
                      <h5>Create Re-Exam</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Program</label>
                          <select
                            name="program_id"
                            className="form-control"
                            value={user.program_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Faculty</label>
                          <select
                            name="college_id"
                            className="form-control"
                            value={user.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            className="form-control"
                            value={user.department_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Department</option>
                            {deptOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            className="form-control"
                            value={user.class_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            className="form-control"
                            value={user.semester_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user.class_id)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            name="session_id"
                            className="form-control"
                            value={user.session_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Month</label>
                          <input
                            type="month"
                            className="form-control"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Employee</label>
                          <select
                            id=""
                            name="employee_id"
                            className="form-control"
                            value={user.employee_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Employee</option>
                            {employeeOpt?.map((i, key) => (
                              <option value={i.value} key={key}>
                                {i.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          onClick={handleSubmit}
                          className="btn btn-primary "
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="card-header">
                      <h6>Re-Examination</h6>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <tr>
                          <th>Sl.No</th>
                          <th>Program</th>
                          <th>Faculty</th>
                          <th>Date</th>
                          <th>Employee</th>
                          <th>Course-Year</th>
                          <th>Semester</th>
                          <th>Action</th>
                        </tr>
                        {data &&
                          data.map((i, key) => (
                            <tr>
                              <td>{key + 1}</td>
                              <td>{i.course}</td>
                              <td>
                                {
                                  collegeOpt?.find((s) => s.id == i.college_id)
                                    ?.name
                                }
                              </td>
                              <td>{i.month + "-" + i.year}</td>
                              <td>
                                {
                                  employeeOpt?.find(
                                    (s) => s.value == i.employee
                                  )?.label
                                }
                              </td>
                              <td>
                                {
                                  classOpt?.find((s) => s.id == i.class_id)
                                    ?.name
                                }
                              </td>
                              <td>
                                {
                                  semesterOpt?.find(
                                    (s) => s.id == i.semester_id
                                  )?.name
                                }
                              </td>
                              <td>
                                <div className="flex">
                                  <button
                                    onClick={() => {
                                      navigate(
                                        ROUTES.Examination.ReExamination.Home +
                                          "/" +
                                          i.id +
                                          "/" +
                                          ROUTES.Examination.ReExamination
                                            .TImeTable
                                      );
                                    }}
                                    className="btn btn-primary btn-sm"
                                  >
                                    {" "}
                                    <i className="ri-external-link-line"></i>{" "}
                                    Explore
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
