import React from 'react'
import {useLocation} from 'react-router-dom'

function Footer() {

    const location = useLocation();

    return (location.pathname!=='/login'&&
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">© Nexenstial University</div>
                    <div className="col-sm-6">
                        <div className="text-sm-right d-none d-sm-block">
                            Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                            <a href="https://www.nexenstial.com" target="_blank">
                                Nexenstial LLP.
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer