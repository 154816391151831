import React from "react";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import { useState, useEffect, useRef } from "react";
import { EXAM_NOTY_UPLOAD } from "../../utils/InfoUploadingApiConst";
import { getFileUrl } from "../../Helpers/Helpers";
import { ASSET_EXAMINATION } from "../../utils/AssetsReferenceTypes";
import { toast } from "react-toastify";

function ExamNotifications() {
  const fileref = useRef();

  const [loading, setLoading] = useState(0);

  const [info, setInfo] = useState({
    faculty: "",
    title: "",
    date: "",
    attachment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      faculty: "",
      title: "",
      date: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    console.log(info);

    if (!info?.date || !info?.attachment || !info?.title || !info?.faculty) {
      toast.error("Please Enter all the required Details");
      return;
    }

    setLoading(1);
    const config = {
      method: "post",
      url: EXAM_NOTY_UPLOAD,
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: info,
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        clearData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_EXAMINATION,
        "Notifications",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.attachment = d;
    } catch (error) {
      console.log(error);
    }
  };

  let data = [
    {
      label: "Science",
      value: "Science",
    },
    {
      label: "Commerce",
      value: "Commerce",
    },
    {
      label: "B.ED",
      value: "B.ED",
    },
    {
      label: "Ayurvedic",
      value: "Ayurvedic",
    },
    {
      label: "Homeopathy",
      value: "Homeopathy",
    },
    {
      label: "Engineering",
      value: "Engineering",
    },
    {
      label: "Nursing",
      value: "Nursing",
    },
    {
      label: "LAW",
      value: "LAW",
    },
    {
      label: "Arts",
      value: "Arts",
    },
    {
      label: "Pharmacy",
      value: "Pharmacy",
    },
  ];

  return (
    <div>
      <Loader loading={loading} />
      <div
        className="col-xl-12 p-0 col-lg-9 d-flex col-md-12 col-sm-12"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div className="card h-100 w-25" style={{ marginTop: "2rem" }}>
          <div className="card-body">
            <div className="row-gutters mt-4" style={{ textAlign: "center" }}>
              <h4>Upload Exam Notifications</h4>
              <div style={{ marginTop: "2.5rem" }}>
                <h5>Select Faculty</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={info?.faculty}
                      name="faculty"
                      onChange={handleChange}
                    >
                      <option value="">Select Faculty</option>
                      {data &&
                        data?.map((item, key) => {
                          return (
                            <option value={item?.value}>{item?.label}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "2.5rem" }}>
                <h5>Title</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title Of the Subject"
                      name="title"
                      value={info?.title}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "2.5rem" }}>
                <h5>Date</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      placeholder="Enter Title Of the Subject"
                      value={info?.date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "2.5rem" }}>
                <h5>Attachment</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      name="attachment"
                      placeholder="Enter Title Of the Subject"
                      ref={fileref}
                      onChange={(e) => {
                        handleChange1(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group" style={{ marginTop: "3rem" }}>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamNotifications;
