import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
} from "../../utils/Academics.apiConst";
import { STUDENT_SESSION, STUDENT_SESSION_ALL } from "../../utils/apiConstants";
import { PRE_EXAM_ENROLL_STUDENT } from "../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../utils/LocalStorageConstants";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

function StudentApplicationForm({ setLoading }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [infoData, setInfoData] = useState([
    {
      subcode: "ML-01",
      subject: "Machine Learning",

      status: <p className="badge badge-soft-success">Applied</p>,
    },
    {
      subcode: "CN-02",
      subject: "Computer Networking",

      status: <p className="badge badge-soft-danger">Not-Applied</p>,
    },
  ]);

  const params = useParams();
  const [studentData, setStudentData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const getCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  const getDepartmentData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(getDepartmentData());

  const session_id = searchParams.get("session_id");
  const program_id = searchParams.get("program_id");
  const class_id = searchParams.get("class_id");
  const semester_id = searchParams.get("semester_id");
  const college_id = searchParams.get("college_id");
  const month = searchParams.get("month");
  const date = searchParams.get("date");

  const year = searchParams.get("year");
  const department_id = searchParams.get("department_id");
  const class_examination_id = searchParams.get("class_examination_id");

  // Examination Fees

  const applicationfees = 100;
  const [examfees, setExamfees] = useState(
    program_id == "04" && college_id == "1111000"
      ? 1250
      : program_id == "04" && college_id == "1111007"
      ? 2500
      : program_id == "05"
      ? 1500
      : 1250
  );

  useEffect(() => {
    setExamfees(
      program_id == "04" && college_id == "1111000"
        ? 1250
        : program_id == "04" && college_id == "1111007"
        ? 2500
        : program_id == "05"
        ? 1500
        : 1250
    );
  }, [program_id]);

  const [collgename, setCollegeName] = useState(
    college_id == 1111008
      ? "Nexenstial College of Pharmacy"
      : college_id == 1111007
      ? "Nexenstial College of Homeopathy"
      : "Nexenstial College of Enginering and Technology"
  );

  // College Names

  useEffect(() => {
    setCollegeName(
      college_id == 1111008
        ? "Nexenstial College of Pharmacy"
        : college_id == 1111007
        ? "Nexenstial Homoeopathic Medical College"
        : college_id == 1111003
        ? "Nexenstial College of Nursing"
        : college_id == 1111001
        ? "Nexenstial Ayurvedic College"
        : "Nexenstial College of Enginering and Technology"
    );
  }, [college_id]);

  const collegeList = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_SESSION_ALL}?student_id=${params?.id}&session_id=${session_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data[0]);
        setStudentData(res.data.data[0]);
      })
      .catch((err) => console.log(err));
    setLoading(0);
  };

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [subjectOpt, setSubjectOpt] = useState([]);

  const [enrollmentData, seEnrollmentData] = useState();

  const getBasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_CLASS}?college_id=${college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config1)
      .then((res) => {
        setSemesterOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  const getSubjects = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?class_id=${class_id}&semester_id=${semester_id}&college_id=${college_id}&department_id=${department_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setSubjectOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  const getStudentEnrollmentDetails = async () => {
    const config = {
      method: "get",
      url: `${PRE_EXAM_ENROLL_STUDENT}?student_id=${params?.id}&session_id=${session_id}&year=${year}&class_id=${class_id}&semester_id=${semester_id}&class_examination_id=${class_examination_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        student_id: params?.id,
        session_id: session_id,
        year: year,
        class_id: class_id,
        semester_id: semester_id,
        class_examination_id: class_examination_id,
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        seEnrollmentData(res.data?.enrolledStudent);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EnrollSubject = async (subject_id) => {
    const config = {
      method: "post",
      url: `${PRE_EXAM_ENROLL_STUDENT}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        student_id: params?.id,
        subject: subject_id,
        class_examination_id: class_examination_id,
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        getStudentEnrollmentDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteEnrollment = async (id) => {
    const config = {
      method: "delete",
      url: `${PRE_EXAM_ENROLL_STUDENT}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        getStudentEnrollmentDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateEnrollmentToActive = async (id) => {
    const config = {
      method: "put",
      url: `${PRE_EXAM_ENROLL_STUDENT}/${id}`,
      data: {
        status: "ACTIVE",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        getStudentEnrollmentDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getBasicData();
  }, [session_id, college_id]);

  useEffect(() => {
    if (class_id && semester_id && college_id && department_id) getSubjects();
  }, [class_id, semester_id, college_id, department_id]);

  useEffect(() => {
    if (
      session_id &&
      params?.id &&
      class_examination_id &&
      year &&
      class_id &&
      semester_id
    ) {
      getStudentEnrollmentDetails();
    }
  }, [
    params?.id,
    session_id,
    class_examination_id,
    year,
    class_id,
    semester_id,
  ]);

  return (
    <div className="">
      <div className="main-content" style={{ backgroundColor: "#fff" }}>
        <div className="page-content">
          <div className="container-fluid px-4">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Student Application Form</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Student Application Form
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row px-5" ref={componentRef}>
              <div className="col-xl-12">
                <h2 className="card-title d-print-none">Student Details</h2>

                <h3 className="text-center d-none d-print-block">
                  Nexenstial University
                </h3>

                <h5 className="text-center d-none d-print-block">
                  Examination Application Form of {month}-{year}
                </h5>
                <br />
                <table className="table table-bordered text-center p-2 ">
                  <tr>
                    <th> Reg. Number</th>
                    <th>Student Name</th>
                    <th>Faculty</th>
                    <th>Department</th>
                    <th>Student Photo</th>
                  </tr>
                  <tr>
                    <td>{studentData?.user_id}</td>
                    <td>{studentData?.name}</td>
                    <td>
                      {
                        collegeOpt?.find(
                          (s) =>
                            s.id ==
                            departmentOpt?.find(
                              (s) => s.id == studentData?.department_id
                            )?.college_id
                        )?.name
                      }
                    </td>
                    <td>
                      {
                        departmentOpt?.find(
                          (s) => s.id == studentData?.department_id
                        )?.name
                      }
                    </td>
                    <td rowSpan={3}>
                      {/* <img
                            src="https://bootdey.com/img/Content/avatar/avatar7.png"
                            calss="img-fluid ml-auto"
                            alt="Maxwell Admin"
                            style={{ borderRadius: "50%" }}
                            width="20%"
                          /> */}
                    </td>
                  </tr>
                  <tr>
                    <th className="d-none d-print-block">College Name</th>
                    <th className="bg-secondary text-white d-print-none">
                      College Name
                    </th>
                    <td colSpan={3}>
                      {/* {collegeList?.find(s=>s.id==college_id)?.name}, Hubli.
                          
                         */}
                      {collgename}, Hubli
                    </td>
                  </tr>
                  <tr>
                    <th className="d-print-block d-none">Course Year</th>
                    <th className="bg-secondary text-white d-print-none">
                      Course Year
                    </th>
                    <td colSpan={3}>
                      {classOpt?.find((s) => s.id == class_id)?.name} -{" "}
                      {semesterOpt?.find((s) => s.id == semester_id)?.name}{" "}
                    </td>
                  </tr>
                </table>

                {/* end card */}

                <h1 className="card-title">Subject List</h1>

                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th>Subject Code</th>
                        <th>Subject Name</th>
                        <th>Status</th>
                        <th className="d-print-none">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subjectOpt &&
                        subjectOpt?.map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{data?.code}</td>
                              <td>{data?.name}</td>
                              <td className="d-print-none">
                                {enrollmentData?.find(
                                  (s) => s?.subject == data?.id
                                ) &&
                                enrollmentData?.find(
                                  (s) => s?.subject == data?.id
                                )?.status == "ACTIVE" ? (
                                  <div className="badge badge-soft-success">
                                    Applied
                                  </div>
                                ) : enrollmentData?.find(
                                    (s) => s?.subject == data?.id
                                  ) &&
                                  enrollmentData?.find(
                                    (s) => s?.subject == data?.id
                                  )?.status == "PENDING" ? (
                                  <div className="badge badge-soft-warning">
                                    Pending for payment
                                  </div>
                                ) : (
                                  <div className="badge badge-soft-danger">
                                    Not Applied
                                  </div>
                                )}
                              </td>

                              <td className="d-none d-print-block">
                                {enrollmentData?.find(
                                  (s) => s?.subject == data?.id
                                ) &&
                                enrollmentData?.find(
                                  (s) => s?.subject == data?.id
                                )?.status == "ACTIVE"
                                  ? "Applied"
                                  : enrollmentData?.find(
                                      (s) => s?.subject == data?.id
                                    )?.status == "PENDING"
                                  ? "Partial"
                                  : "Not Applied"}
                              </td>
                              <td className="d-print-none">
                                {enrollmentData?.find(
                                  (s) => s?.subject == data?.id
                                ) &&
                                enrollmentData?.find(
                                  (s) => s?.subject == data?.id
                                )?.status == "ACTIVE" ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      DeleteEnrollment(
                                        enrollmentData?.find(
                                          (s) => s?.subject == data?.id
                                        ).id
                                      )
                                    }
                                  >
                                    {" "}
                                    Cancel
                                  </button>
                                ) : enrollmentData?.find(
                                    (s) => s?.subject == data?.id
                                  ) &&
                                  enrollmentData?.find(
                                    (s) => s?.subject == data?.id
                                  )?.status == "PENDING" ? (
                                  <button
                                    className="btn btn-warning"
                                    onClick={() =>
                                      UpdateEnrollmentToActive(
                                        enrollmentData?.find(
                                          (s) => s?.subject == data?.id
                                        ).id
                                      )
                                    }
                                  >
                                    {" "}
                                    Apply
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => EnrollSubject(data?.id)}
                                  >
                                    <i
                                      class="fa fa-check "
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Apply
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <h5 className="card-title"> Fee Detials</h5>

                <table className="table table-bordered text-center ">
                  <thead className="">
                    <tr>
                      <th>Sl. No.</th>
                      <th>Description</th>
                      <th>Fee (Rs.)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Examination Fees</td>
                      <td>{examfees}</td>
                    </tr>
                    {/* <tr>
                                    <td>02</td>
                                    <td>Marks Card Fees</td>
                                    <td>20</td>
                                </tr> */}
                    <tr>
                      <td>02</td>
                      <td>Application Fee</td>
                      <td>{applicationfees}</td>
                    </tr>
                    {/* <tr>
                                    <td>04</td>
                                    <td>Penalty Fee</td>
                                    <td>0</td>
                                </tr> */}
                    <tr className="bg-light">
                      <th className="text-right " colSpan={2}>
                        Total Amount (Rs.):
                      </th>
                      <th>{parseInt(applicationfees) + parseInt(examfees)}</th>
                    </tr>
                  </tbody>
                </table>
                <button
                  className="btn btn-danger btn-sm d-print-none float-right"
                  onClick={handlePrint}
                >
                  <i class="ri-printer-line"></i> Print Application
                </button>
                <div className="row mt-2 mb-5">
                  <div className="col-md-12">
                    <h5>Disclaration</h5>
                    <p className="ml-5">
                      {" "}
                      The Subjects Listed in this Application are the only
                      Subjects wish to apply for {month}-{year} Examination.
                      Further I Understand this Application Overrides any
                      Previous Applications. I may have Submitted.{" "}
                    </p>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    {/* <h6>Date: {date}-{month}-{year}</h6> */}
                  </div>
                  <div className="col-md-5">
                    <p className="text-right">Candidate Signature with date</p>
                    {/* <button className="btn btn-success float-right"> Submit</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentApplicationForm;
