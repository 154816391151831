import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { ACADEMICS_ADD_SUBJECT } from "../../utils/Academics.apiConst";
import {
  PRE_EXAM_TIMETABLE,
  QPDS_QP_SETTER,
  QPDS_SETTER_UPLOAD_QP,
} from "../../utils/Examination.apiConst";
import { LOCAL_EMPLOYEE } from "../../utils/LocalStorageConstants";

function ModalExamSetter(props) {
  //Destructuring props
  const {
    addData,
    data,
    programOpt,
    collegeOpt,
    classOpt,
    semesterOpt,
    showCollege,
    showProgram,
    showMonth,
    showYear,
    setLoading,
  } = props;

  const employee = JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE));

  console.log("emp", employee);

  //Main obj
  const [user, setUser] = useState({
    class_examination_id: addData?.id,
    session_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  //Other States for data management
  const [subOpt, setSubOpt] = useState([]);

  //states holding created time table data
  const [timeTableData, setTimeTableData] = useState([]);

  const [addNew, setAddNew] = useState(0);

  const [addNewId, setAddNewId] = useState("");

  const [addNewData, setAddNewData] = useState([]);

  const [setterId, setSetterId] = useState();

  const [questionPaper, setQuestionPaper] = useState();

  const [questionPaperRemark, setQuestionPaperRemark] = useState("");

  const [addNewObj, setAddNewObj] = useState({
    staff_full_name: "",
    employee_id: "",
    staff_email_id: "",
    question_paper_set: "A",
    is_employee: true,
  });

  const clearAddNewObj = () => {
    setAddNewObj({
      staff_full_name: "",
      employee_id: "",
      staff_email_id: "",
      question_paper_set: "A",
      is_employee: true,
    });
  };

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //////get Subject Data
  const getbasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?college_id=${showCollege}&semester_id=${addData?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setSubOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  //get time table data
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${addData?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const getAddNewData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${QPDS_QP_SETTER}?time_table_id=${addNewId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setAddNewData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const handleAddNewData = async () => {
    if (
      !addNewObj?.staff_full_name ||
      !addNewObj?.staff_email_id ||
      !addNewObj?.question_paper_set
    )
      return toast.error("Name, Email and Question Paper Set is Required");
    setLoading(1);

    const config = {
      method: "post",
      url: `${QPDS_QP_SETTER}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        time_table_id: addNewId,
        is_employee: addNewObj?.is_employee,
        staff_full_name: addNewObj?.staff_full_name,
        staff_email_id: addNewObj?.staff_email_id,
        question_paper_set: addNewObj?.question_paper_set,
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getAddNewData();
    clearAddNewObj();
    setLoading(0);
  };

  const handleDeleteAddNewData = async (dd) => {
    setLoading(1);

    const config = {
      method: addNewData ? "put" : "post",
      url: `${QPDS_QP_SETTER}/${dd}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getAddNewData();
    setLoading(0);
  };

  const getQuestionPaper = async () => {
    const config = {
      method: "get",
      url: `${QPDS_SETTER_UPLOAD_QP}?qp_setter_id=${setterId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setQuestionPaper(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ApproveDeclinePaper = async (status, id) => {
    if (status == "REJECTED" && questionPaperRemark == "")
      return toast.error("Please add remark to reject");
    const config = {
      method: "put",
      url: QPDS_SETTER_UPLOAD_QP + "/" + id,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
        remark: questionPaperRemark,
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Success");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });

    getQuestionPaper();
  };

  useEffect(() => {
    if (addNew == 2) getQuestionPaper();
  }, [addNew]);

  ////useEffects
  useEffect(() => {
    if (addData) {
      setUser({
        class_examination_id: addData?.id,
        session_id: "",
        course_id: "",
        date: "",
        time_from: "",
        time_to: "",
        duration: "",
        max_marks: "",
        min_marks: "",
      });
      getbasicData();
      getData();
    }
    setAddNew(0);
  }, [addData]);

  useEffect(() => {
    if (addNew && addNewId) {
      getAddNewData();
    }
  }, [addNew, addNewId]);

  return (
    <div
      className="modal fade"
      id="create"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-100 w-100 ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Create Time Table
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {addNew == 1 ? (
            <div className="modal-body">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setAddNew(0);
                }}
              >
                Back
              </button>
              <table className="table mt-4">
                <tbody className="text-center">
                  <tr>
                    <th>Sl.No</th>
                    <th>Is Employee</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Question Paper set</th>
                    <th>status</th>
                    <th>Action</th>
                  </tr>

                  {addNewData && addNewData?.length != 0 ? (
                    addNewData?.map((i, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{i?.is_employee ? "YES" : "NO"}</td>
                        <td>{i?.staff_full_name}</td>
                        <td>{i?.staff_email_id}</td>
                        <td>{i?.question_paper_set}</td>
                        <td>
                          <span
                            className={`badge badge-soft-${
                              i?.status == "PENDING"
                                ? "warning"
                                : i?.status == "APPROVED"
                                ? "success"
                                : "danger"
                            }`}
                          >
                            {i?.status == "INACTIVE" ? "Deleted" : i?.status}
                          </span>
                        </td>
                        <td>
                          <a
                            role="button"
                            class="badge badge-light p-1 mr-2"
                            data-toggle="tooltip"
                            title="View Uploaded document"
                            onClick={() => {
                              setSetterId(i?.id);
                              setAddNew(2);
                            }}
                          >
                            <i class="ri-file-pdf-line"></i>
                          </a>
                          {/* <a href="" role="button" class="badge badge-light text-success p-1 mr-2" data-toggle="tooltip" title="Edit" ><i class="ri-edit-2-line"></i></a> */}
                          <a
                            class="badge badge-light text-danger p-1 mr-2"
                            onClick={() => {
                              handleDeleteAddNewData(i?.id);
                            }}
                          >
                            <i class="ri-delete-bin-2-line"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Please add A Quesion Paper setter</td>
                    </tr>
                  )}

                  <tr>
                    <th>{}</th>
                    <th>
                      <select
                        type="number"
                        className="form-control"
                        value={addNewObj?.is_employee}
                        onChange={(e) => {
                          setAddNewObj((prev) => ({
                            ...prev,
                            is_employee: e.target.value,
                          }));
                        }}
                      >
                        <option value={true}>true</option>
                        <option value={false}>false</option>
                      </select>
                    </th>
                    {addNewObj?.is_employee == "true" ||
                    addNewObj?.is_employee === true ? (
                      <>
                        <th>
                          <Select
                            className="font-normal"
                            onChange={(e) => {
                              setAddNewObj((prev) => ({
                                ...prev,
                                employee_id: e?.value,
                                staff_email_id: employee?.find(
                                  (s) => s?.id == e?.value
                                )?.email,
                              }));
                            }}
                            options={employee?.map((s) => {
                              return {
                                value: s.id,
                                label: s?.first_name + " " + s?.last_name,
                              };
                            })}
                          />
                        </th>
                        <th>
                          {
                            employee?.find(
                              (s) => s?.id == addNewObj?.employee_id
                            )?.email
                          }
                        </th>
                      </>
                    ) : (
                      <>
                        <th>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="eg. Suresh"
                            value={addNewObj?.staff_full_name}
                            onChange={(e) => {
                              setAddNewObj((prev) => ({
                                ...prev,
                                staff_full_name: e.target.value,
                              }));
                            }}
                          />
                        </th>
                        <th>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="eg. test@nexenstial.com"
                            value={addNewObj?.staff_email_id}
                            onChange={(e) => {
                              setAddNewObj((prev) => ({
                                ...prev,
                                staff_email_id: e.target.value,
                              }));
                            }}
                          />
                        </th>
                      </>
                    )}

                    <th>
                      <select
                        className="form-control"
                        value={addNewObj?.question_paper_set}
                        onChange={(e) => {
                          setAddNewObj((prev) => ({
                            ...prev,
                            question_paper_set: e.target.value,
                          }));
                        }}
                      >
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                        <option value="F">F</option>
                      </select>
                    </th>
                    <td>
                      <button
                        className="mr-2 btn btn-success"
                        onClick={handleAddNewData}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : addNew == 2 ? (
            <div className="modal-body">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setAddNew(1);
                }}
              >
                Back
              </button>
              <table className="table mt-3">
                <tr>
                  <th></th>
                  <th>Link</th>
                  <th>Remark</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                {questionPaper ? (
                  <tr>
                    <th> Question Paper</th>
                    <td>
                      <a href={questionPaper?.file_url} target="_blank">
                        Click Here
                      </a>
                    </td>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Remark Here"
                        value={questionPaperRemark}
                        onChange={(e) => {
                          setQuestionPaperRemark(e.target.value);
                        }}
                      />
                    </td>
                    <td>{questionPaper?.status}</td>
                    <td>
                      <button
                        className="btn btn-success mr-3"
                        onClick={() => {
                          ApproveDeclinePaper("APPROVED", questionPaper?.id);
                        }}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          ApproveDeclinePaper("REJECTED", questionPaper?.id);
                        }}
                      >
                        Decline
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr>No Question Paper Found</tr>
                )}
              </table>
            </div>
          ) : (
            <div className="modal-body">
              <div className="row mt-4">
                <div className="col-lg-12  table-responsive ">
                  <table
                    className="table table-bordered nowrap table-hover "
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        <th>Subjects</th>
                        <th>Date</th>
                        <th>Time From</th>
                        <th>Time To</th>
                        <th>
                          Duration <br /> (in min)
                        </th>

                        <th> Marks (Max..)</th>
                        <th>Marks (Min..)</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="examsub">
                      {/* <div></div> */}
                      {timeTableData?.map((i, key) => (
                        <tr key={key}>
                          <td>
                            {subOpt?.find((s) => s.id == i?.course_id)?.name}
                          </td>
                          <td>{i?.date?.split("T")[0]}</td>
                          <td>{i?.time_from}</td>
                          <td>{i?.time_to}</td>
                          <td>{i?.duration}</td>
                          <td>{i?.max_marks}</td>
                          <td>{i?.min_marks}</td>
                          <td className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn badge badge-light text-success p-1 mr-2"
                              title="Add Setter"
                              onClick={() => {
                                setAddNew(1);
                                setAddNewId(i?.id);
                              }}
                            >
                              <i class="ri-add-line"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {/* <div className="modal-footer">
                        <button
                            type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            aria-label="Close"
                        >Save changes</button>
                    </div> */}
        </div>

        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default ModalExamSetter;
