import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { Modal, TimePicker } from 'antd';
import { Http } from '../../../../Services/Services';
import { RE_EXAM_ENROLLMENT, RE_EXAM_TIMETABLE } from '../../../../utils/Examination.apiConst';
import { ACADEMICS_ADD_SUBJECT } from '../../../../utils/Academics.apiConst';
import { toast } from 'react-toastify';
import { ROUTES } from '../../../../Router/routerConfig';

import Bar from "react-barcode";
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';


const Barcode = () => {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const [modalIsOpen, setModalIsOpen] = useState(false);


    const { examId } = useParams()
    const navigete = useNavigate()

    const { examData } = useOutletContext()

    console.log(examData);

    const [data, setData] = useState([])
    const [subjectOpt, setSubjectOpt] = useState([])
    const [enrollementData, setEnrollementData] = useState([])
    const [barcodeSubject, setBarcodeSubject] = useState('')
    const [BarcodeStatus, setBarcodeStatus] = useState('')

    const handleOpenModal = () => {
        setModalIsOpen(true);
    }

    const handleCloseModal = () => {
        setModalIsOpen(false);
    }

    const getData = () => {
        Http.get(`${RE_EXAM_TIMETABLE}?re_examination_id=${examId}`)
            .then(res => {
                setData(res.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getSubjectData = () => {
        Http.get(`${ACADEMICS_ADD_SUBJECT}?semester_id=${examData?.semester_id}`)
            .then(res => {
                setSubjectOpt(res.data.data);
            })
            .catch(err => {
                console.log(err);
            }
            )
    }

    const printEnrollmentData = async(id) => {
        await Http.get(`${RE_EXAM_ENROLLMENT}?time_table_id=${id}`)
            .then(res => {
                setEnrollementData(res.data.data);
                handleOpenModal()
                setBarcodeStatus('DO You Want To Print Barcode?')
            })
            .catch(err => {
                console.log(err);
            })
    }


    useEffect(() => {
        if (examId) getData();
    }, [examId])

    useEffect(() => {
        if (examData?.semester_id) getSubjectData();
    }, [examData?.semester_id])
    return (
        <div className='card' style={{ maxWidth: '100%', overflow: 'scroll' }}>
            <Modal open={modalIsOpen} onOk={()=>{handlePrint();handleCloseModal();}} onCancel={handleCloseModal}>
                {
                    BarcodeStatus
                }
            </Modal>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="card-header">Student Enrolment</h6>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <tr>
                                <th>Sl. No</th>
                                <th style={{ minWidth: '150px' }}>Subjects</th>
                                <th>Date</th>
                                <th style={{ minWidth: '150px' }}>Time From</th>
                                <th style={{ minWidth: '150px' }}>Time To</th>
                                <th>
                                    Duration <br /> (in min)
                                </th>

                                <th style={{ minWidth: '100px' }}> Marks (Max..)</th>
                                <th style={{ minWidth: '100px' }}>Marks (Min..)</th>
                                <th>Instructions</th>
                                <th>Remuneration</th>

                                <th >Action</th>
                            </tr>
                            {
                                data && data.map((i, key) => (
                                    <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{subjectOpt?.find(s => s.id == i?.course_id)?.name}</td>
                                        <td>{i?.date?.split("T")[0]}</td>
                                        <td>{i?.time_from}</td>
                                        <td >{i?.time_to}</td>
                                        <td>{i?.duration}</td>
                                        <td>{i?.max_marks}</td>
                                        <td>{i?.min_marks}</td>
                                        <th>Instructions</th>
                                        <th>Remuneration</th>

                                        <th >
                                            <button onClick={async () => {
                                                console.log('here')
                                                handleOpenModal()
                                                await setBarcodeSubject(i?.course_id)
                                                await printEnrollmentData(i.id)
                                            }} className="btn btn-sm btn-primary">
                                                Print
                                            </button>
                                        </th>
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </div>

                <div style={{ display: "none" }}>
                    <div
                        className="d-flex flex-row flex-start flex-wrap px-5 mr-2  "
                        style={{ marginTop: "10px" }}
                    ref={componentRef}
                    >
                        <div className="row mb-3" style={{ marginBottom: "55px" }}>
                            {enrollementData?.map((i, key) => {
                                const uid = i.id
                                const sid = subjectOpt
                                    ?.find((s) => s?.id == barcodeSubject)
                                    ?.code?.replace(/\s/g, "");
                                console.log(uid, sid);
                                let zeros = new Array(
                                    9 -
                                    Number(uid?.length || 0) +
                                    1
                                ).join("0");
                                // for(let iterator = 0 ; iterator < 12 - uid?.length - sid?.length ; iterator++){
                                //     zeros += '0'
                                // }

                                const val = uid + "SUKR" + zeros;

                                return (
                                    <div className="col-6 px-4 d-flex justify-content-around align-items-center " style={{ marginBottom: "45px", marginTop: "10px" }}>
                                        <div className="barcode-small">
                                            {val} <br />
                                            {i?.student_id} <br />
                                            {subjectOpt
                                                ?.find((s) => s?.id == barcodeSubject).name}
                                        </div>

                                        <div
                                            className="barcode-large text-right"
                                            style={{ marginLeft: "26px" }}
                                        >
                                            <Bar width={1.1} height={70} value={val} displayValue="false" />  <br />
                                            {subjectOpt
                                                ?.find((s) => s?.id == barcodeSubject).name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default Barcode