


import React, { useState, useEffect } from 'react'
import Nodata from '../../Components/NoData/Nodata'
import axios from 'axios'
import {toast} from 'react-toastify'
import { LOCAL_COLLEGE, LOCAL_PROGRAM } from '../../utils/LocalStorageConstants'
import useEmployee from '../../Hooks/Employee/useEmployee'
import Multiselect from 'multiselect-react-dropdown'
import { PRE_EXAM_COMMITTEE } from '../../utils/Examination.apiConst'

function Exam_committee({setLoading}) {

  ////Methods to get local data
  const getLocalPrograms = () => localStorage.getItem(LOCAL_PROGRAM)?JSON.parse(localStorage.getItem(LOCAL_PROGRAM)):null
  const getLocalColleges = () => localStorage.getItem(LOCAL_COLLEGE)?JSON.parse(localStorage.getItem(LOCAL_COLLEGE)):null


  //Main Object
  const [user, setUser] = useState(
    {
      program_id : "",
      college_id : '',
      employee : [],
      month : '',
      year : ''
    }
  )

  //States holds dropdown data
  const [ProgramOpt, setProgramOpt] = useState(getLocalPrograms())
  const [collegeOpt, setCollegeOpt] = useState(getLocalColleges())
  const [employeeOpt] = useEmployee(user?.college_id)


  //main Data
  const [data,setData] = useState([])

  //Other States
  const [employeeArray, setEmployeeArray] = useState([])
  const [newValue, setNewValue] = useState(false)
  const [edit, setEdit] = useState(false)

  //handle Change function for every change in value
  const handleChange = (e) =>{
    const {name,value} = e.target;
    setUser(prev => ({
      ...prev,
      [name]:value
    }))
  }

  //ClearData all data
  const clearData = () => {
    setUser({
      program_id : "",
      college_id : '',
      month : '',
      year : ''
    })
    setEmployeeArray([])
  }


  //Get Data 
  const getData = async() =>{
    if(!user.month||!user.year||!user.college_id||!user.program_id) return toast.error("All fields are mandatory")
    const config = {
      method:'get',
      url:`${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      setData(res.data.data)
      if(res.data.data.length==0) setNewValue(true)
    })
    .catch(err=>{
      toast.error('Something went wrong')
    })
  }

  //Submit Data
  const handleSubmit = async() =>{
    if(employeeArray.length==0) return toast.error('atleast one employee required')

    const config = {
      method:'post',
      url:`${PRE_EXAM_COMMITTEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data:{
        ...user,
        employee : employeeArray?.map(item => item.id),
      }
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      setNewValue(false)
      setEmployeeArray([])
      getData()
    })
    .catch(err=>{
      toast.error('Something went wrong')
    })

  }

  //update
  const handleEdit = async() =>{
    if(employeeArray.length==0) return toast.error('atleast one employee required')

    const config = {
      method:'put',
      url:`${PRE_EXAM_COMMITTEE}/${user?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data:{
        ...user,
        employee : employeeArray?.map(item => item.id),
      }
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      setNewValue(false)
      setEmployeeArray([])
      getData()
    })
    .catch(err=>{
      toast.error('Something went wrong')
    })

  }


  //Delete
  const handleDelete = async(id) =>{

    const config = {
      method:'put',
      url:`${PRE_EXAM_COMMITTEE}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data:{
        status:"INACTIVE"
      }
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      setNewValue(false)
      getData()
    })
    .catch(err=>{
      toast.error('Something went wrong')
    })

  }


  return (
    <div className='Exam_committee'>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Exam Committee</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Exam Committee
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="income source">
                          Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select 
                          class="form-control" 
                          name="program_id" 
                          id="course"
                          value={user.program_id}
                          onChange={handleChange}
                          >
                            <option value="">Select Program</option>
                            {
                              ProgramOpt?.map((i,key)=>(
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="income source">
                          Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select 
                          class="form-control" 
                          name="college_id" 
                          id="speci"
                          value={user?.college_id}
                          onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {
                              collegeOpt?.map((i,key)=>(
                                <option value={i?.id} key={key}>{i?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input 
                          type="month" 
                          className='form-control' 
                          name='year' 
                          id="year"
                          value={user?.year + '-' + user?.month}
                          onChange={e=>{
                            setUser(prev=>({
                              ...prev,
                              month:e.target.value?.split("-")[1],
                              year:e.target.value?.split("-")[0],
                            }))
                          }}
                          />
                        </div>
                      </div>
                      {
                        newValue
                        &&
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="income source">
                            Staff<span style={{ color: "red" }}>*</span>
                            </label>
                            <Multiselect
                              options={employeeOpt}
                              selectedValues={employeeArray}
                              onSelect={(p) => { setEmployeeArray(p) }}
                              onRemove={(p) => { setEmployeeArray(p) }}
                              displayValue={"first_name"}   
                            />
                          </div>
                        </div>                     
                      }
                    </div>
                    {
                      newValue
                      ?
                      <div className="row ">
                        {
                          edit
                          ?
                          <div className="col-md-12 ">
                          <button
                            className="btn btn-nex btn-rounded float-right  "
                            type="submit"
                            name="submit"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Edit
                          </button>
                        </div>:
                        <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Save
                        </button>
                      </div>
                        }
                      </div>
                      :
                      <div className="row ">
                        <div className="col-md-12 ">
                          <button
                            className="btn btn-nex btn-rounded float-right  "
                            type="submit"
                            name="submit"
                            onClick={getData}
                          >
                            <i className="fa fa-search" aria-hidden="true" /> Search
                          </button>
                        </div>
                      </div>

                    }
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>


            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className='card-title text-uppercase '>Exam Committee</h4>
                    <hr />

                    <div className="table-responsive">

                      <table
                        id="table_id"
                        className="display table table-bordered  nowrap table-hover "
                        style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th> Sl. No.</th>
                            <th>Course</th>
                            <th>Faculty</th>
                            <th>Faculties</th>
                            <th>Year</th>


                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>



                          {
                            data && data?.length!=0 ? data?.map((i, key) => {
                              return <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{ProgramOpt?.find(s=>s.id==i?.program_id)?.name}</td>
                                <td> {collegeOpt?.find(s=>s.id==i?.college_id)?.name}</td>
                                <td> {i?.employee?.map((j,key2)=>(
                                  <div>{key2+1}. {employeeOpt?.find(s => s.id == j)?.first_name}</div>
                                ))}</td>
                                <td> {i?.month+ '/' +i?.year}</td>


                                <td><span 
                                className='badge badge-light text-dark mr-3' 
                                data-toggle="tooltip" 
                                title="Edit"
                                onClick={()=>{
                                  setNewValue(true);
                                  setEdit(true)
                                  setUser(i)
                                  const m =[]
                                  employeeOpt?.map(s=>{
                                      if(i?.employee?.find(k=>k==s?.id)) m.push(s)
                                  })
                                  setEmployeeArray([...m])
                                }}
                                > <i class="fa fa-edit " aria-hidden="true"></i></span>
                                  <span className='badge badge-light text-danger mr-3' data-toggle="tooltip" title="Delete" onClick={() => handleDelete(i?.id)}> <i class="fa fa-trash " aria-hidden="true"></i></span>
                                </td>
                              </tr>
                            })
                            :
                            <tr>
                              <td colSpan={10}>
                                <Nodata/>
                              </td>
                            </tr>


                          }
                        </tbody>
                      </table>

                   

                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div>



    </div>
  )
}

export default Exam_committee